"use client";
import {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faVolumeUp, faVolumeMute} from "@fortawesome/free-solid-svg-icons";

const VideoSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleToggleSound = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.muted = true; // Mute the video
        setIsPlaying(false);
      } else {
        videoRef.current.muted = false; // Unmute the video
        videoRef.current.play(); // Ensure the video is playing
        setIsPlaying(true);
      }
    }
  };

  return (
    <div className="relative w-full h-screen mt-32 overflow-hidden">
      {/* Video Background */}
      <div className="absolute inset-0 z-10 overflow-hidden">
        <video id="video1" ref={videoRef} autoPlay loop playsInline muted preload="auto" className="w-full h-full object-cover" poster="/video/video1.jpg">
          <source src="/video/history.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 nebula-bg-overlay-2" />
      </div>

      {/* Content and Button */}
      <div className="relative z-20 flex flex-col items-center justify-center h-full text-center">
        <div className="max-w-4xl mx-auto px-4">
          <h1 className="text-4xl xs:text-6xl sm:text-7xl uppercase font-tungsten-semibold font-semibold text-shadow-lg text-white mb-3" data-aos="fade-up">
            A Leap from Abacus to the Age of AI
          </h1>
          <p className="text-2xl xs:text-2xl sm:text-2xl uppercase  font-semibold text-shadow-lg text-white" data-aos="fade-up">
            2000 Years of Innovation from Progress to Collective Intelligence
          </p>
        </div>
        {/* Play Button */}
        <button onClick={handleToggleSound} className="mt-8 px-6 py-3 text-lg font-medium text-white bg-primary rounded-md shadow hover:bg-red-700 focus:outline-none flex items-center space-x-2">
          <FontAwesomeIcon icon={isPlaying ? faVolumeMute : faVolumeUp} />
          {/* <span>{isPlaying ? "Stop Sound" : "Play Sound"}</span> */}
        </button>
      </div>
    </div>
  );
};

export default VideoSection;
